import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const HeroPatternAlt = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="587"
        height="469"
        viewBox="0 0 587 469"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M372.688 361.814V288.032C372.688 282.785 376.942 278.532 382.188 278.532H454.218C460.865 278.532 466.255 283.921 466.255 290.569V291.917C466.255 337.056 429.662 373.648 384.523 373.648C377.987 373.648 372.688 368.35 372.688 361.814Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M242.466 364.306V321.043C242.466 272.735 281.627 233.574 329.934 233.574C333.302 233.574 336.032 236.304 336.032 239.672V364.148C336.032 369.395 331.779 373.648 326.532 373.648H251.808C246.649 373.648 242.466 369.466 242.466 364.306Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M407.633 436.658H457.298C505.605 436.658 544.766 397.497 544.766 349.19C544.766 345.822 542.036 343.092 538.669 343.092H414.192C408.946 343.092 404.692 347.345 404.692 352.592V433.717C404.692 435.342 406.009 436.658 407.633 436.658Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M147.003 209.125H148.445C151.872 209.125 154.651 211.903 154.651 215.331V293.191C154.651 298.438 150.397 302.691 145.151 302.691H65.6318C62.2642 302.691 59.5342 299.961 59.5342 296.593C59.5342 248.286 98.6951 209.125 147.003 209.125Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M88.0925 467.659H89.5346C92.962 467.659 95.7404 464.881 95.7404 461.453V383.593C95.7404 378.346 91.4871 374.093 86.2404 374.093H6.72166C3.35403 374.093 0.624023 376.823 0.624023 380.191C0.624023 428.498 39.785 467.659 88.0925 467.659Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M423.219 277.749H424.662C428.089 277.749 430.867 274.971 430.867 271.543V193.683C430.867 188.436 426.614 184.183 421.367 184.183H341.849C338.481 184.183 335.751 186.913 335.751 190.281C335.751 238.588 374.912 277.749 423.219 277.749Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M270.208 139.76H328.219C332.419 139.76 335.824 143.165 335.824 147.365C335.824 194.84 297.339 233.326 249.864 233.326H248.447C244.173 233.326 240.708 229.861 240.708 225.587V169.26C240.708 152.967 253.916 139.76 270.208 139.76Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M290.171 374.098H364.737C369.984 374.098 374.237 378.351 374.237 383.598V458.164C374.237 463.41 369.984 467.664 364.737 467.664H290.171C284.924 467.664 280.671 463.41 280.671 458.164V383.598C280.671 378.351 284.924 374.098 290.171 374.098Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M524.323 9.96738V53.2305C524.323 101.538 485.162 140.699 436.855 140.699C433.487 140.699 430.757 137.969 430.757 134.601V10.125C430.757 4.87829 435.01 0.625 440.257 0.625L514.981 0.625C520.141 0.625 524.323 4.80772 524.323 9.96738Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M492.058 333.598V125.856C492.058 121.384 495.683 117.759 500.155 117.759C547.358 117.759 585.624 156.025 585.624 203.228V333.598C585.624 338.844 581.37 343.098 576.124 343.098H501.558C496.311 343.098 492.058 338.844 492.058 333.598Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M270.827 467.651H107.801C101.366 467.651 96.1304 462.471 96.0623 456.036C95.5852 410.917 132.029 374.085 177.152 374.085H257.327C270.48 374.085 281.103 384.822 280.964 397.974L280.327 458.252C280.272 463.459 276.035 467.651 270.827 467.651Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M110.693 368.069V310.381C110.693 306.18 114.099 302.775 118.299 302.775C165.774 302.775 204.259 341.261 204.259 388.736V389.858C204.259 394.117 200.807 397.569 196.549 397.569H140.193C123.901 397.569 110.693 384.362 110.693 368.069Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

HeroPatternAlt.defaultProps = {};

export default HeroPatternAlt;

import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const HeroPatternCondensed = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="701"
        height="320"
        viewBox="0 0 701 320"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M437.703 248.524C437.648 253.731 433.411 257.923 428.204 257.923H265.177C258.742 257.923 253.507 252.743 253.439 246.308C252.961 201.189 289.406 164.357 334.528 164.357H414.703C427.856 164.357 438.48 175.094 438.34 188.246L437.703 248.524Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="268.07"
          y="187.841"
          width="94.7943"
          height="93.5661"
          rx="9.5"
          transform="rotate(-90 268.07 187.841)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M541.899 226.92C535.363 226.92 530.064 221.622 530.064 215.086V141.304C530.064 136.057 534.318 131.804 539.564 131.804H611.594C618.241 131.804 623.631 137.193 623.631 143.841V145.189C623.631 190.328 587.038 226.92 541.899 226.92Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M148.223 143.897C153.382 143.897 157.565 148.08 157.565 153.239V196.503C157.565 244.81 118.404 283.971 70.0967 283.971C66.7291 283.971 63.9991 281.241 63.9991 277.873L63.9991 153.397C63.9991 148.15 68.2523 143.897 73.4991 143.897L148.223 143.897Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="0.999268"
          y="111.897"
          width="95.1164"
          height="93.5661"
          rx="9.5"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M409.184 226.921C404.025 226.921 399.842 222.738 399.842 217.578V174.315C399.842 126.008 439.003 86.8466 487.311 86.8466C490.678 86.8466 493.408 89.5766 493.408 92.9442V217.421C493.408 222.667 489.155 226.921 483.908 226.921H409.184Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M561.068 285.99C561.068 287.614 562.385 288.931 564.009 288.931H613.674C661.981 288.931 701.142 249.77 701.142 201.463C701.142 198.095 698.412 195.365 695.045 195.365H570.568C565.322 195.365 561.068 199.618 561.068 204.865V285.99Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M208.91 145.865C208.91 97.5579 248.071 58.397 296.379 58.397H297.821C301.248 58.397 304.027 61.1754 304.027 64.6028V142.463C304.027 147.71 299.774 151.963 294.527 151.963H215.008C211.64 151.963 208.91 149.233 208.91 145.865Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M598.91 125.865C598.91 77.5579 638.071 38.397 686.379 38.397H687.821C691.248 38.397 694.027 41.1754 694.027 44.6028V122.463C694.027 127.71 689.773 131.963 684.527 131.963H605.008C601.64 131.963 598.91 129.233 598.91 125.865Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M158 201.463C158 249.77 197.161 288.931 245.469 288.931H246.911C250.338 288.931 253.117 286.153 253.117 282.725V204.865C253.117 199.618 248.863 195.365 243.617 195.365H164.098C160.73 195.365 158 198.095 158 201.463Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M437.047 235.869C437.047 230.623 441.3 226.369 446.547 226.369H521.113C526.36 226.369 530.613 230.623 530.613 235.869V310.436C530.613 315.682 526.36 319.936 521.113 319.936H446.547C441.3 319.936 437.047 315.682 437.047 310.436V235.869Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

HeroPatternCondensed.defaultProps = {};

export default HeroPatternCondensed;
